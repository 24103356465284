<template>
  <div>
    <CharityName  v-model="form.name" :no-save="noSave" :errors-post="errorsPost.name || []"  />
    <CharityNumber v-model="form.number" :no-save="noSave" :errors-post="errorsPost.number || []" />
  </div>
</template>

<script>
import CharityName from "../../../question/questions/will/charity/ChairtyName";
import CharityNumber from "../../../question/questions/will/charity/CharityNumber";

export default {
  name: 'CharityWill',
  components: {CharityNumber, CharityName},
  mixins: [],
  watch: {
    form: {
      deep: true,
      immediate: false,
      handler(value) {
        // charity form fields are not saved at field level as they are in json array, so watch
        // form and save whole form to json data
        this.$emit('save', value)
      }
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    noSave: {
      type: Boolean,
      default: true
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>