<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Guardians</h3>

    <template v-if="childrenYoung.length">
      <SeparateGuardians
          v-model="form.data.guardians.details.separateGuardians"
          :childrenData="childrenYoung"
          :callbackFunction="enable"
      />

      <template v-if="details.separateGuardians">

        <IndividualGuardians
            v-model="form.data.guardians.details.individual"
            :callbackFunction="assignChildren"
        />

        <template v-if="details.individual === false">

          <div class="question-box with-arrow">
            <PrimaryGuardians
                v-model="form.data.guardians.details.primary"
                :details="form.data.guardians.details"
                :optionsData="primaryOptions"
            />
          </div>

          <SecondaryYN
              v-if="details.primary.length || details.secondary.length"
              v-model="form.data.guardians.details.secondaryYN"
              :details="form.data.guardians.details"
              :callbackFunction="clearSecondary"
          />
          <div v-if="secondaryRequired && (details.primary.length || details.secondary.length)"
               class="question-box with-arrow">
            <SecondaryGuardians
                v-model="form.data.guardians.details.secondary"
                :details="form.data.guardians.details"
                :optionsData="secondaryOptions"
            />
          </div>

        </template>

        <template v-else-if="details.individual">

          <div v-for="(child, index) in form.data.guardians.details.children"
               :key="'childGuardians' + index">

            <div v-if="childOptions[index]" class="question-box with-arrow">
              <PrimaryChildGuardians
                  v-model="form.data.guardians.details.children[index].guardians.primary"
                  :options-data="childOptions[index].primaryOptions"
                  :index="index"
                  :child="child"
              />
            </div>
            <ChildSecondaryYN
                v-if="details.children[index].guardians.primary.length"
                v-model="form.data.guardians.details.children[index].guardians.secondaryYN"
                :index="index"
                :child="child"
            />

            <div
                v-if="childOptions[index] && details.children[index].guardians.secondaryYN"
                class="question-box with-arrow">
              <SecondaryChildGuardians
                  v-model="form.data.guardians.details.children[index].guardians.secondary"
                  :options-data="childOptions[index].secondaryOptions"
                  :index="index"
                  :child="child"
              />
            </div>

            <ChildBackupYN
                v-if="details.children[index].guardians.secondary.length || details.children[index].guardians.backup.length"
                v-model="form.data.guardians.details.children[index].guardians.backupYN"
                :index="index"
                :child="child"
            />

            <div
                v-if="childOptions[index] && details.children[index].guardians.backupYN"
                class="question-box with-arrow">

              <BackupChildGuardians
                  v-model="form.data.guardians.details.children[index].guardians.backup"
                  :options-data="childOptions[index].backupOptions"
                  :index="index"
                  :child="child"
              />
            </div>

          </div>

        </template>

        <ChildrenDirection
            v-if="[true, false].includes(details.individual)"
            v-model="form.data.guardians.details.direction"
        />
      </template>

    </template>

    <template v-else>
      <p>You have told us that you do not have any children.</p>
    </template>

  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {willGuardiansHelpers} from "./helpers/willGuardiansHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import PrimaryGuardians from "../../question/questions/will/guardians/PrimaryGuardians";
import SecondaryGuardians from "../../question/questions/will/guardians/SececondaryGuardians";
import SecondaryYN from "../../question/questions/will/guardians/SecondaryYN";
import SeparateGuardians from "../../question/questions/will/guardians/SeparateGuardians";
import ChildrenDirection from "../../question/questions/will/guardians/ChildrenDirections";
import IndividualGuardians from "../../question/questions/will/guardians/IndividualGuardians";
import PrimaryChildGuardians from "../../question/questions/will/guardians/children/PrimaryChildGuardians";
import ChildSecondaryYN from "../../question/questions/will/guardians/children/ChildSecondaryYN";
import SecondaryChildGuardians from "../../question/questions/will/guardians/children/SecondaryChildGuardians";
import ChildBackupYN from "../../question/questions/will/guardians/children/ChildBackupYN";
import BackupChildGuardians from "../../question/questions/will/guardians/children/BackupChildGuardians";

export default {
  name: 'Guardians',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willGuardiansHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    BackupChildGuardians,
    ChildBackupYN,
    SecondaryChildGuardians,
    ChildSecondaryYN,
    PrimaryChildGuardians,
    IndividualGuardians,
    ChildrenDirection,
    SeparateGuardians,
    SecondaryYN,
    SecondaryGuardians,
    PrimaryGuardians
  },
  mounted () {
    // refresh people on mount if will instruction has children section
    this.$store.dispatch('peopleFetch')
  },
  data() {
    return {
      label: 'Guardians', // used for steps progress
      primaryOptions: [],
      secondaryOptions: [],
      backupOptions: [],
      db: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.guardians.details'
      },
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    details() {
      return this.form.data.guardians.details
    },
    storePeople() {
      return this.$store.getters.people
    }
  }
};
</script>
