<template>
  <QuestionBase :id="db.saveField"

                :errors="[...jsErrors]"
                :question="question"
                :valid="!!data.length">
    <div class="accordion sub-items">

      <!-- Selected People -->
      <transition-group name="fade">
        <ObjectCard v-for="(person, index) in data"
                    :id="'guardianPrimaryData' + index"
                    :key="'guardianPrimaryData' + person.id"
                    :ref="'guardianPrimaryData' + index"
                    :select-mode="true"
                    :selected="true"
                    :show-delete="false"
                    :title="cardTitle(person)"
                    :value="data[index]"
                    type="guardianPrimaryData"
                    :loading="loading"
                    @click="deselectConfirm(person, 'Remove person from your primary Guardians?')"
                    @delete="deletePerson(person.id)"
                    @save="savePerson( null, 'guardianPrimaryData'+index)">

          <PersonWill v-if="person.type === 'person'"
                      :key="'guardianPrimary-form-data' + person.id"
                      v-model="data[index]"
                      :errors-post="errorsPatch"
                      :no-save="true"
                      :objectId="person.id"
                      @save="savePersonForm($event, person)" />

        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <template v-if="!details.primaryPartner">
        <b-button class="btn-question w-100"
                  @click="show.addPerson=true">
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Primary Guardian
        </b-button>
      </template>
    </transition>

    <WillPeopleModal v-model="show.addPerson"
                     :dataSelected="data"
                     :options="optionsData"
                     :show-charities="false"
                     :show-groups="false"
                     :sub-title="'Select your guardians by selecting existing people or adding new people. You can select multiple individuals for this role.'"
                     :hide-type-options="true"
                     title="Add Primary Guardian"
                     @dataSelectedUpdate="data=$event"
                     @save="save" />

  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "../../objects/peopleSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willPeopleObjectHelpers} from "../helpers/willPeopleObjectHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PersonWill from "../../../../fieldsets/sub/people/PersonWill";
import WillPeopleModal from "../helpers/WillPeopleModal";

export default {
  name: 'PrimaryGuardians',
  components: {
    WillPeopleModal,
    PersonWill,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    details: {
      type: Object,
      required: true
    },
    optionsData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: 'primaryGuardians',
        formPath: 'data.guardians.details.primary',
        jsonSaveField: 'primary',
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'You must name at least one primary guardian, click to select'
      },
      serverErrors: [],
      errorsPost: {},
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    question() {
      return {
        title: 'Who would look after any children initially?',
        subTitle: 'Please confirm who you would like to act as the primary guardians. To deselect a person, simply click on their name.',
        tip: null
      }
    },
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
