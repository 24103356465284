<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="guardiansIndividualOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
        class="radio-options-wide"
    />
  </QuestionBase>
</template>

<script>
import { willRelationshipHelpers } from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import { willPeopleHelpers } from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { willOptions } from "../../../options/willOptions";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'IndividualGuardians',
  mixins: [questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers, willOptions],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: Boolean,
      required: false
    },
    callbackFunction: {
      type: Function,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Would you like to appoint separate guardians for each individual child or the same guardians for all of your children?',
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: 'individualGuardians',
        formPath: 'data.guardians.details.individual',
        jsonSaveField: 'individual',
        callbackFunction: this.callbackFunction
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false
    }
  }
}
</script>
