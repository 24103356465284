<template>
  <b-modal v-model="showModal"
           :hide-header="true"
           modal-class="modal-style-one"
           scrollable
           size="lg"
           @hidden="clearSelectedPeople"
           @ok="addPeople"
           @shown="shown">
    <div class="questionnaire">
      <QuestionBase :disabledValid="true"
                    :errors="[...jsErrors]"
                    :question="question"
                    :sub-section="true"
                    :valid="!!show.type">
        <div v-show="!hideTypeOptions"
             class="radio-options-wide radio-options w-75">
          <label v-for="(option, index) in typeOptions"
                 :key="index"
                 :class="{ active: option.value === show.type }"
                 class="radio"
                 @click="show.type = typeOptions[index].value">
            <span>{{ option.label }}</span>
          </label>
        </div>
      </QuestionBase>

      <div :class="{ 'pl-20 pr-36': !hideTypeOptions }">
        <div v-if="show.type" :class="{ 'with-arrow question-box': !hideTypeOptions, 'grey-bg-accordion pb-12': hideTypeOptions }">
          <div v-if="show.type === 'person'">
            <div v-if="!show.newPerson"
                 class="accordion sub-items" :class="{ 'mt-0': hideTypeOptions }">
              <ObjectCard v-for="(person, index) in options"
                          :id="'person' + person.id"
                          :key="'person' + person.id"
                          :ref="'person' + index"
                          :select-mode="true"
                          :selected="
                !!selectedPeople.find((option) => option.id === person.id)
              "
                          :title="cardTitle(person)"
                          :value="options[index]"
                          type="executorPrimary"
                          :loading="loading"
                          @click="selectPerson(person)"
                          @delete="deletePerson(person.id)"
                          @save="savePerson(null, 'person' + index)">
                <PersonWill v-if="person.type === 'person'"
                            v-model="options[index]"
                            :no-save="false"
                            :objectId="person.id" />

                <ProfessionalWill v-else-if="person.type === 'professional'"
                                  v-model="options[index]" />
              </ObjectCard>
            </div>
            <b-button v-if="!show.newPerson"
                      class="btn-question w-100"
                      @click="show.newPerson = true">
              <i class="i-Add text-25 font-weight-800 mr-2"> </i>New Person
            </b-button>

            <PersonWill v-if="show.newPerson"
                        v-model="formData.person"
                        :errors-post="errorsPost"
                        :noSave="true" />
          </div>

          <div v-if="show.type === 'group' && showGroups">
            <div v-if="!show.newGroup"
                 class="accordion sub-items">
              <ObjectCard v-for="(group, index) in groupOptions"
                          :key="'group' + index"
                          :ref="'group' + index"
                          v-model="groupOptions[index]"
                          :index="index"
                          :select-mode="true"
                          :selected="
                !!selectedGroups.find((option) => option.name === group.name)
              "
                          :show-confirm="false"
                          :show-delete="false"
                          :show-save="true"
                          :title="cardTitle(group)"
                          type="group"
                          :loading="loading"
                          @click="selectGroup(group)">
                <GroupWill v-model="groupOptions[index]" />
              </ObjectCard>
            </div>
            <b-button v-if="!show.newGroup"
                      class="btn-question w-100"
                      @click="show.newGroup = true">
              <i class="i-Add text-25 font-weight-800 mr-2"> </i>Custom Group
            </b-button>
            <GroupWill v-if="show.newGroup"
                       v-model="formData.group" />
          </div>

          <div v-if="show.type === 'charity' && showCharities">
            <div v-if="!show.newCharity"
                 class="accordion sub-items">
              <ObjectCard v-for="(charity, index) in charityOptions"
                          :key="'group' + index"
                          :ref="'group' + index"
                          v-model="charityOptions[index]"
                          :index="index"
                          :select-mode="true"
                          :selected="
                !!selectedCharities.find(
                  (option) => option.name === charity.name
                )
              "
                          :show-confirm="false"
                          :show-delete="false"
                          :show-save="true"
                          :title="cardTitle(charity)"
                          type="group"
                          :loading="loading"
                          @click="selectCharity(charity)">
                <CharityWill v-model="charityOptions[index]" />
              </ObjectCard>
            </div>
            <b-button v-if="!show.newCharity"
                      class="btn-question w-100"
                      @click="show.newCharity = true">
              <i class="i-Add text-25 font-weight-800 mr-2"> </i>Custom Charity
            </b-button>
            <CharityWill v-if="show.newCharity"
                         v-model="formData.charity" />
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button class="btn btn-backwards white-border"
                @click="cancel">
          Cancel
        </button>
        <button class="btn btn-forwards"
                @click="ok">Confirm
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {peopleSaveHelpers} from "../../objects/peopleSaveHelpers";
import {willRelationshipHelpers} from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willOptions} from "../../../options/willOptions";
import {clientHelpers} from "@/mixins/clientHelpers";

import ObjectCard from "../../objects/ObjectCard";
import PersonWill from "../../../../fieldsets/sub/people/PersonWill";
import ProfessionalWill from "../../../../fieldsets/sub/people/ProfessionalWill";
import GroupWill from "../../../../fieldsets/sub/people/GroupWill";
import CharityWill from "../../../../fieldsets/sub/people/CharityWill";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";

export default {
  name: "WillPeopleModal",
  mixins: [
    personHelpers,
    blankForms,
    peopleSaveHelpers,
    willRelationshipHelpers,
    willOptions,
    clientHelpers
  ],
  components: {
    QuestionBase,
    CharityWill,
    GroupWill,
    ProfessionalWill,
    PersonWill,
    ObjectCard,
  },
  mounted() {
    this.setForm();
  },
  props: {
    value: {
      // show modal
      type: Boolean,
      required: true,
    },
    showGroups: {
      // show group tab
      // must always show people
      type: Boolean,
      default: false,
    },
    showCharities: {
      // show charity tab
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    dataSelected: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    hideTypeOptions: {
      type: Boolean,
      default: false,
    },
    noSave: {
      type: Boolean,
      default: false,
    },
    addPersonFunction: {
      type: Function,
      required: false
    }
  },
  computed: {
    showModal: {
      // show modal
      set(value) {
        this.$emit("input",
            value);
      },
      get() {
        return this.value;
      },
    },
    data: {
      // main selected people data
      set(value) {
        this.$emit("dataSelectedUpdate",
            value);
      },
      get() {
        return this.dataSelected;
      },
    },
    form: {
      // abstraction for person form to be compatible with peopleSaveHelpers.js
      set(value) {
        this.formData.person = value;
      },
      get() {
        return this.formData.person;
      },
    },
    typeOptions() {
      // type of allowed people options for radio buttons
      let options = [
        {
          label: "Person",
          value: "person",
        },
      ];
      if (this.showGroups) {
        options.push({
          label: "Group",
          value: "group",
        });
      }
      if (this.showCharities) {
        options.push({
          label: "Charity",
          value: "charity",
        });
      }
      return options;
    },
    question() {
      return {
        title: this.title,
        subTitle: this.subTitle,
        tip: null,
      };
    },
  },
  data() {
    return {
      // temporary selected people in modal
      show: {
        type: null,
        newPerson: false,
        newGroup: false,
        newCharity: false,
      },
      selectedPeople: [],
      selectedGroups: [],
      selectedCharities: [], // new
      formData: {
        person: null,
        group: null,
        charity: null,
      }, // new person errors
      errorsPost: {}, // group options: set on shown
      groupOptions: [],
      charityOptions: [],
      jsErrors: [],
    };
  },
  methods: {
    addPeople(bvModalEvt) {
      console.log("add people");
      bvModalEvt.preventDefault();
      // tab 0 = existing people select
      // tab 1 = new person form
      if (this.show.type === "person" && !this.show.newPerson) {
        // if selected people
        for (let i in this.selectedPeople) {
          // if adding existing person
          let person = this.selectedPeople[i]
          // modify person with supplied function
          if (this.addPersonFunction) person = this.addPersonFunction(person)
          // add person
          this.data.push(person);
        }
        this.clearSelectedPeopleAndClose();
        this.save();
      } else if (this.show.type === "person" && this.show.newPerson) {
        // if adding new person
        let person = this.formData.person
        // modify person with supplied function
        console.log(person)
        if (this.addPersonFunction) person = this.addPersonFunction(person)
        console.log(person)

        // add person
        this.savePerson(person,
            null,
            this.clearSelectedPeopleAndClose,
            true,
            this.addPersonFunction);
      } else if (this.show.type === "group" && !this.show.newGroup) {
        // if selected groups

        for (let i in this.selectedGroups) {
          console.log("push group to data");
          this.data.push(this.selectedGroups[i]);
        }
        this.clearSelectedPeopleAndClose();
        this.save();
      } else if (this.show.type === "group" && this.show.newGroup) {
        // if new group
        this.data.push(this.formData.group);
        this.clearSelectedPeopleAndClose();
        this.save();
      } else if (this.show.type === "charity" && !this.show.newCharity) {
        // if selected charities

        for (let i in this.selectedCharities) {
          this.data.push(this.selectedCharities[i]);
        }
        this.clearSelectedPeopleAndClose();
        this.save();
      } else if (this.show.type === "charity" && this.show.newCharity) {
        // if new charity
        console.log("here 6");

        this.data.push(this.formData.charity);
        this.clearSelectedPeopleAndClose();
        this.save();
      }


      this.$emit("added",
          this.data);
    },
    cardTitle(person) {
      if ([
        "professional",
        "group",
        "charity"
      ].includes(person.type)) return this.capitalise(person.name);
      return `${this.fullName(person)} (${person.relationship})`;
    },
    setForm() {
      this.formData.person = Object.assign({},
          this.personBlankForm);
      this.formData.person.dependant = false;
      this.formData.group = Object.assign({},
          this.groupWillBlankForm);
      this.formData.charity = Object.assign({},
          this.charityWillBlankForm);
    },
    selectPerson(value) {
      // add person to temporary selected people waiting for confirm
      // is person is already in temporary selected people
      let index = this.selectedPeople.findIndex((person) => person.id === value.id);
      if (index >= 0) {
        // remove
        this.selectedPeople.splice(index,
            1);
      } else {
        // add to
        this.selectedPeople.push(value);
      }
    },
    selectGroup(value) {
      // add group to temporary selected groups waiting for confirm
      // is group is already in temporary selected people
      let index = this.selectedGroups.findIndex((group) => group.name === value.name);
      if (index >= 0) {
        // remove
        this.selectedGroups.splice(index,
            1);
      } else {
        // add to
        this.selectedGroups.push(value);
      }
    },
    selectCharity(value) {
      // add group to temporary selected groups waiting for confirm
      // is group is already in temporary selected people
      let index = this.selectedCharities.findIndex((charity) => charity.name === value.name);
      if (index >= 0) {
        // remove
        this.selectedCharities.splice(index,
            1);
      } else {
        // add to
        this.selectedCharities.push(value);
      }
    },
    clearSelectedPeople() {
      this.selectedPeople = [];
      this.selectedGroups = [];
      this.selectedCharities = [];
      this.groupOptions = [];
      this.charityOptions = [];

      this.show.newPerson = false;
      this.show.newGroup = false;
      this.show.newCharity = false;
      this.show.type = null;
      this.setForm();
    },
    clearSelectedPeopleAndClose() {
      this.clearSelectedPeople();
      this.showModal = false;
    },
    save() {
      if (this.noSave) return;
      this.$nextTick(() => {
        this.$emit("save");
      });
    },
    shown() {
      if (this.showGroups) this.setGroups();
      if (this.showCharities) this.setCharities();
      if (this.typeOptions.length === 1) this.show.type = "person";
    },
    setGroups() {
      // get selected groups from data
      let selectedGroups = this.data.map((option) => {
        if (option.type === "group") return option.name;
      });
      // construct group options
      for (let i in this.groupNames) {
        // if already selected then skip
        if (selectedGroups.includes(this.groupNames[i])) continue;
        // else add option
        let data = this.clone(this.groupWillBlankForm);
        data.name = this.groupNames[i];
        this.groupOptions.push(data);
      }

      // add partner group options
      // get partner from store
      let partner = this.$store.getters.people.find(person => this.isPartner(person))
      if (!partner) return

      let prefix = `${partner.relationship_to_client}'s`

      for (let i in this.groupNames) {
        // if already selected then skip
        if (selectedGroups.includes(this.groupNames[i])) continue;
        // else add option
        let data = this.clone(this.groupWillBlankForm);
        data.name = `${prefix} ${this.groupNames[i]}`
        this.groupOptions.push(data);
      }
    },
    setCharities() {
      // get selected charities from data
      let selectedCharities = this.data.map((option) => {
        if (option.type === "charity") return option.name;
      });
      // construct charity options
      for (let i in this.charityNames) {
        // if already selected then skip
        if (selectedCharities.includes(this.charityNames[i][0])) continue;
        // else add option
        let data = this.clone(this.charityWillBlankForm);
        data.name = this.charityNames[i][0]; // name in array
        data.number = this.charityNames[i][1]; // number in array
        this.charityOptions.push(data);
      }
    },
  },
};
</script>
