export const willProfessionalOptions = {
    data () {
        return {
            professionalDunham: {
                name:  'Dunham McCarthy Associates Ltd',
                type: 'professional',
                address: 'Barn 1, Dunston Business Village, Penkridge, Staffordshire, ST18 9AB',
                preferredPartner: null,
                limitNumberOfPartnersToAct: true,
                succeededFirm: true,
                condition: null
            }
        }
    }
}