<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { willRelationshipHelpers } from "../../../../../fieldsets/will/helpers/willRelationshipHelpers";
import { willPeopleHelpers } from "../../../../../fieldsets/will/helpers/willPeopleHelpers";
import { questionLoadHelpers } from "../../../../helpers/questionLoadHelpers";
import { general } from "../../../../options/general";

import QuestionBase from "../../../../QuestionBase";
import InputRadio from "../../../../inputs/InputRadio";

export default {
  name: 'ChildSecondaryYN',
  mixins: [general, questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    index: {
      type: Number,
      required: true
    },
    child: {
      type: Object,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  `Would you like to name secondary guardians for ${this.child.name_first}?`,
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `secondaryYNGuardiansChild${this.index}`,
        formPath: `data.guardians.details.children.${this.index}.guardians.secondaryYN`,
        jsonSaveField: 'secondaryYN'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
