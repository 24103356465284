export const willOptions = {
    data() {
        return {
            executorsTypeOfOptions: [
                {
                    label: 'Professional',
                    value: 'professional'
                },
                {
                    label: 'Friends & Family',
                    value: 'friends_family'
                },
                {
                    label: 'Friends & Family With Professional',
                    value: 'friends_family_professionals'
                }
            ],
            trusteesTypeOfOptions: [
                {
                    label: 'Professional',
                    value: 'professional'
                },
                {
                    label: 'Friends & Family',
                    value: 'friends_family'
                },
                {
                    label: 'Friends & Family With Professional',
                    value: 'friends_family_professionals'
                }
            ],
            guardiansIndividualOptions: [
                {
                    label: 'Separate for each child',
                    value: true
                },
                {
                    label: 'Same for all children',
                    value: false
                },
            ],
            funeralTypeOptions: [
                {
                    label: 'Buried',
                    value: 'burial'
                },
                {
                    label: 'Cremated',
                    value: 'cremation'
                },
                {
                    label: 'No preference',
                    value: false
                }
            ],
            organDonorOptions: [
                {
                    label: 'Yes, for transplants',
                    value: 'therapy'
                },
                {
                    label: 'Yes, for research',
                    value: 'research'
                },
                {
                    label: 'Yes, for research and transplants',
                    value: 'researchTherapy'
                },
                {
                    label: 'No',
                    value: 'donorNone'
                }
            ],
            funeralStyleOptions: [
                {
                    label: 'Celebration of my life',
                    value: 'celebration'
                },
                {
                    label: 'Simple and respectful',
                    value: 'simple'
                },
                {
                    label: 'Green eco friendly',
                    value: 'green'
                },
                {
                    label: 'Grand send off',
                    value: 'grand'
                },
                {
                    label: 'Non religious',
                    value: 'nonReligious'
                },
                {
                    label: 'No preference',
                    value: false
                },
            ],
            groupNames: [
                'children',
                'grandchildren',
                'siblings',
                'parents',
                'brothers',
                'sisters',
                'nieces',
                'nephews',
                'grandparents'
            ],
            charityNames: [
                ['Cancer Research UK', '1089464']
            ],
            giftJewelleryType: [
                {
                    label: 'All my Jewellery',
                    value: 'all'
                },
                {
                    label: 'Choice of my Jewellery',
                    value: 'choice'
                },
                {
                    label: 'Specific',
                    value: 'custom'
                }
            ],
            giftPropertyType: [
                {
                    label: 'Building',
                    value: 'all'
                },
                {
                    label: 'Land',
                    value: 'land'
                },
                {
                    label: 'Specific',
                    value: 'custom'
                }
            ],
            giftAccountType: [
                {
                    label: 'All Bank Accounts',
                    value: 'allBank'
                },
                {
                    label: 'Specific Bank Account',
                    value: 'specificBank'
                },
                {
                    label: 'All ISA Accounts',
                    value: 'allIsa'
                },
                {
                    label: 'Stock/Share Account',
                    value: 'stockShare'
                },
                {
                    label: 'MINI ISA Account',
                    value: 'miniIsa'
                },
                {
                    label: 'Cash ISA Account',
                    value: 'cashIsa'
                },
                {
                    label: 'MAXI ISA Accounts',
                    value: 'maxiIsa'
                },
                {
                    label: 'Specific',
                    value: 'custom'
                }
            ],
            giftVehicleType: [
                {
                    label: 'All Vehicles',
                    value: 'all'
                },
                {
                    label: 'Car',
                    value: 'car'
                },
                {
                    label: 'Boat',
                    value: 'boat'
                },
                {
                    label: 'Caravan',
                    value: 'caravan'
                },
                {
                    label: 'Custom',
                    value: 'custom'
                }
            ]
        }
    }
}
