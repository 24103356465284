<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"

                :valid="!!data.length">
    <div class="question-box with-arrow">
      <div class="accordion sub-items">

        <!-- Selected People -->
        <transition-group name="fade">
          <ObjectCard v-for="(person, index) in data"
                      :id="db.saveField + 'Data' + index"
                      :key="'guardianPrimaryData' + person.id"
                      :ref="db.saveField + 'Data' + index"
                      :select-mode="true"
                      :selected="true"
                      :title="cardTitle(person)"
                      :value="data[index]"
                      :show-delete="false"
                      type="guardianPrimaryData"
                      :loading="loading"
                      @click="deselectConfirm(person, 'Remove person from your primary guardians?')"
                      @delete="deletePerson(person.id)"
                      @save="savePerson( null, db.saveField + 'Data' + index)">

            <PersonWill v-if="person.type === 'person'"
                        v-model="data[index]"
                        :key="'guardianPrimary-form-data' + person.id"
                        :errors-post="errorsPatch"
                        :no-save="true"
                        :objectId="person.id"
                        @save="savePersonForm($event, person)"/>

          </ObjectCard>
        </transition-group>

      </div>

      <!-- New People -->
      <transition name="fade">
        <b-button
            class="btn-question w-100"
            @click="show.addPerson=true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Primary Guardian
        </b-button>
      </transition>

      <WillPeopleModal
          v-model="show.addPerson"
          :options="optionsData"
          :dataSelected="data"
          :sub-title="'Select guardians by selecting existing people or adding new people. You can select multiple individuals for this role.'"
          :hide-type-options="true"
          :show-charities="false"
          :show-groups="false"
          title="Add Guardian"
          @dataSelectedUpdate="data=$event"
          @save="save"
      />
    </div>
  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "../../../objects/peopleSaveHelpers";
import {questionLoadHelpers} from "../../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willPeopleObjectHelpers} from "../../helpers/willPeopleObjectHelpers";

import QuestionBase from "../../../../QuestionBase";
import ObjectCard from "../../../objects/ObjectCard";
import PersonWill from "../../../../../fieldsets/sub/people/PersonWill";
import WillPeopleModal from "../../helpers/WillPeopleModal";

export default {
  name: 'PrimaryChildGuardians',
  components: {
    WillPeopleModal,
    PersonWill,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    optionsData: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    child: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'You must have more than one Primary Guardian, click to select'
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `primaryGuardiansChild${this.index}`,
        formPath: `data.guardians.details.children.${this.index}.guardians.primary`,
        jsonSaveField: 'primary',
      },
      serverErrors: [],
      errorsPost: {},
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    question() {
      return {
        title: `Who would you want to look after ${this.fullName(this.child)}?`,
        subTitle: 'Please confirm who you would like to act as the primary guardians. To deselect a person, simply click on their name.',
        tip: null
      }
    },
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
