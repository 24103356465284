<template>
  <label
      :class="{ 'active' : data}"
      @click="changeValue"
      class="radio"
  >
    <span>Other</span>
  </label>
</template>

<script>
import { saveHelpers} from "@/views/questionnaires/saveHelpers";

export default {
  name: 'InputCheckBasic',
  mixins: [saveHelpers],
  props: {
    value: {
      type: Boolean,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    db: {
      type: Object,
      required: true
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    jsErrors: {
      type: Array,
      default: () => {
        return []
      }
    },
    skipped: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
        if (this.noSave) return
        this.$nextTick(() => {
          this.save()
        })
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    changeValue() {
      this.data = !this.data
    }
  }
}
</script>