<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {general} from "../../../options/general";
import {personHelpers} from "@/mixins/personHelpers";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'SeparateGuardians',
  mixins: [questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers, general, personHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: Boolean,
      required: false
    },
    childrenData: {
      type: Array,
      required: true
    },
    callbackFunction: {
      type: Function,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    question() {
      let children = 'your children'
      if (this.childrenData.length === 1) {
        children = this.listPeopleAddressDob({people: this.children, noDob: true, firstName: true})
      }
      return {
        title: `Would you like to appoint guardians to look after ${children}?`,
        subTitle: 'Any parent who has parental responsibility for their child may appoint one or more individuals ' +
            'to be the child\'s guardian. As you have stated that you have children under the age of 18 ' +
            '(or you are expecting a child), it is important to appoint a guardian in your Will.',
        tip: null,
      }
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: 'separateGuardians',
        formPath: 'data.guardians.details.separateGuardians',
        jsonSaveField: 'separateGuardians',
        callbackFunction: this.callbackFunction
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false
    }
  }
}
</script>
