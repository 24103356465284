<template>
  <div :id="`${db.saveField}${db.objectId ? db.objectId : ''}`"
       class="radio-options w-100 mt-2">
    <label v-if="args.label" class="sub-label">{{ args.label }}</label>

    <template v-for="(option, index) in options">
      <label v-if="!option.disabled"
             :key="index + db.saveField"
             :class="{ 'active' : data.includes(option.value), 'disabled' : skipped, 'error-border': [...jsErrors, ...errorsPost, ...serverErrors].length}"
             @click="add(options[index].value)"
             class="radio"
      >
        <span>{{ option.label }}</span>
      </label>
      <label v-if="option.disabled"
             :key="index + db.saveField"
             class="radio disabled muted"
      >
        <span>{{ option.label }}</span>
      </label>

    </template>


    <slot>

    </slot>

    <div v-if="args.skippable" class="add-or flex-shrink-0 mb-20">
      <label class="fancy-checkbox-container">'Skip'
        <input type="checkbox" :value="skipped" :checked="skipped ? 'checked': null" @click="skipQuestion" >
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
import {skipHelpers} from "../questions/helpers/skipHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import { clone } from "@/mixins/clone";

export default {
  name: 'InputRadioMultiple',
  mixins: [saveHelpers, skipHelpers, clone],
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: false
    },
    db: {
      type: Object,
      required: true
    },
    args: {
      type: Object,
      default: () => {
        return {}
      }
    },
    skipped: {
      type: Boolean,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    jsErrors: {
      type: Array,
      default: () => {
        return []
      }
    },
    showOther: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      serverErrors: []
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
        if (this.noSave) return
        this.$nextTick(() => {
          this.save()
        })
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    add(value) {
      breakThis: if (!this.skipped && !this.data.includes(value)) {
        // add to array
        this.data.push(value)
        this.$emit('input', this.data)
        if (this.noSave) break breakThis
        this.$nextTick(() => {
          this.save()
        })
      } else if (!this.skipped) {
        // remove from array
        let index = this.data.findIndex(option => option === value)
        this.data.splice(index, 1)
        this.$emit('input', this.data)
        if (this.noSave) break breakThis
        this.$nextTick(() => {
          this.save()
        })
      }
      this.$emit('added', value)
    }
  }
}
</script>


<style scoped lang="scss">
.error-border {
  border: 2px solid red
}
.muted{
  opacity: 0.3;
}
</style>