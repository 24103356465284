var moment = require('moment');

import { clientHelpers } from '@/mixins/clientHelpers';

export const dateFormat = {
    mixins: [clientHelpers],
    methods: {
        formatDateAndTime(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY HH:mm');
            }
            return 'NO DATE';
        },
        formatDateAndTimeAmPm(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY HH:mm a');
            }
            return 'NO DATE';
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
            return 'NO DATE';
        },
        formatDateShort(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YY');
            }
            return 'NO DATE';
        },
        formatDateLong(value) {
            if (value) {
                return moment(String(value)).format('HH:mm ddd Do MMM ');
            }
            return 'NO DATE';
        },
        formatDateDescription(value) {
            if (value) {
                return moment(String(value)).format('Do MMMM');
            }
            return 'NO DATE';
        },
        formatTime(value) {
            if (value) {
                return moment(String(value)).format('HH:mm');
            }
            return 'NO TIME';
        },
        formatDayOnly(value) {
            if (value) {
                return moment(String(value)).format('D');
            }
            return 'NO DATE';
        },
        letterDate(value) {
            if (value) {
                return moment(value).format('dddd, Do MMMM YYYY');
            }
            return 'NO DATE';
        },
        letterDateTwo(value) {
            if (value) {
                return moment(value).format('Do MMMM YYYY');
            }
            return 'NO DATE';
        },
        formatDateAlternative(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD');
            }
            return 'NO DATE';
        },
        formatDob(args) {
            // init
            let date = 'date' in args ? args.date : false;
            let strong = 'strong' in args ? args.strong : false;
            let error =
                'error' in args
                    ? args.error
                    : '<strong class="text-red">CONFIRM DOB</strong>';
            // start
            if (date) {
                let string = moment(String(date)).format('Do MMMM YYYY');
                if (strong)
                    return `<span class="font-weight-700 text-info">${string}</span>`;
                return string;
            }
            return error;
        },
        formatDateAndTimeLong(args) {
            // init
            let date = 'date' in args ? args.date : false;
            let error = 'error' in args ? args.error : 'NO DATE';
            //start
            if (date) {
                return moment(String(date)).format('Do MMMM YYYY HH:mm');
            }
            return error;
        },
        getTime() {
            return moment().format('h:mm');
        },
        isChild(dob) {
            if (!dob) return false;
            let birthDate = new Date(dob);
            let now = new Date();
            return (
                now <=
                new Date(
                    birthDate.getFullYear() + 18,
                    birthDate.getMonth(),
                    birthDate.getDate()
                )
            );
        },
        isOverAge(dob, age) {
            if (!dob) return false;
            let birthDate = new Date(dob);
            let now = new Date();
            return (
                now >=
                new Date(
                    birthDate.getFullYear() + age,
                    birthDate.getMonth(),
                    birthDate.getDate()
                )
            );
        },
        isUnderAge(dob, age) {
            if (!dob) return false;
            let birthDate = new Date(dob);
            let now = new Date();
            return (
                now <=
                new Date(
                    birthDate.getFullYear() + age,
                    birthDate.getMonth(),
                    birthDate.getDate()
                )
            );
        },
        ageYears(dob) {
            if (!dob) return 'No DOB';
            return moment().diff(dob, 'years');
        },
        ageDays(dob) {
            if (dob) return moment().diff(dob, 'days');
            return '?';
        },
        timeUntil(date) {
            return new moment().to(moment(date, 'days'));
        },
        isChildOf(child) {
            return !!(this.isChild(child.dob) && this.isSonOrDaughter(child));
        },
        fromNow(date) {
            return moment(date).parseZone().fromNow(true);
        },
        timePast(startTime) {
            let x = new Date(startTime);
            return moment(x).fromNow();
            // let now = new Date();
            // console.log(x);

            // let timeDiff = Math.abs(now - x);

            // var a = moment(x);
            // var b = moment(now);
            // console.log('a-b',a.diff(b)) // 86400000
            // console.log('timeDiff',timeDiff);
            // console.log(a.fromNow());
            
            // timeDiff /= 1000;

            // let seconds = Math.round(timeDiff);
            // timeDiff = Math.floor(timeDiff / 60);

            // let minutes = Math.round(timeDiff % 60);
            // timeDiff = Math.floor(timeDiff / 60);

            // let hours = Math.round(timeDiff % 24);
            // timeDiff = Math.floor(timeDiff / 24);

            // let days = Math.round(timeDiff % 365);
            // timeDiff = Math.floor(timeDiff / 365);

            // let years = timeDiff;

            // if (years > 0) {
            //     return years + (years > 1 ? ' Years ' : ' Year ') + 'ago';
            // } else if (days > 2) {
            //     return days + (days > 1 ? ' Days ' : ' Day ') + 'ago';
            // } else if (hours > 1) {
            //     return hours + (hours > 1 ? ' Hours ' : ' Hour ') + 'ago';
            // } else if (minutes > 1) {
            //     return minutes + (minutes > 1 ? ' Minutes ' : ' Min ') + 'ago';
            // } else if (seconds > 0) {
            //     return seconds + (seconds > 1 ? ' Seconds ago' : ' second ago');
            // }
            // return 'Just Now';
        }
    }
};
