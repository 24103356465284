<template>
  <QuestionBase :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]" :valid="!!data" :disabled-valid="true">
    <InputBasic
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        :noSave="noSave"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputBasic from "../../../inputs/InputBasic";

export default {
  name: 'GroupName',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'How would you describe the group?',
        subTitle: `In most cases a group description would start with 'my'. For example 'my wife's sibblings' or 'my sister's children'.`,
        tip: null
      },
      db: {
        saveLocation: null,
        saveField: null, // was 'name'
        objectId: this.objectId
      },
      args: {
        placeholder: 'e.g. my sisters',
        label: null,
        type: 'text',
        skippable: false,
        required: false,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>