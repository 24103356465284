<template>
  <div>
    <FullName
        :noSave="noSave"
        :objectId="objectId"
        :value-first="form.name_first"
        :value-middle="form.name_middle"
        :value-last="form.name_last"
        :errors-post-first="errorsPost.name_first || []"
        :errors-post-middle="errorsPost.name_middle || []"
        :errors-post-last="errorsPost.name_last || []"
        @inputFirst="form.name_first=$event"
        @inputMiddle="form.name_middle=$event"
        @inputLast="form.name_last=$event"
    />

    <Relationship
        v-model="form.relationship"
        :no-save="noSave"
        :errors-post="errorsPost.relationship_to_client || []"
        :objectId="objectId"
    />

    <Parents
        v-if="childListOptions.includes(form.relationship)"
        v-model="form.clients"
        :no-save="noSave"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
    />

    <Dob
        v-model="form.dob"
        :noSave="noSave"
        :errors-post="errorsPost.dob || []"
        :objectId="objectId"
    />

    <Gender
        v-model="form.gender"
        :noSave="noSave"
        :objectId="objectId"
        :errors-post="errorsPost.gender || []"
    />

  </div>
</template>

<script>
import {clientPersonOptions} from "../../../question/options/clientPersonOptions";

import FullName from "../../../question/questions/clientPerson/FullName";
import Dob from "../../../question/questions/clientPerson/Dob";
import Relationship from "../../../question/questions/clientPerson/Relationship";
import Gender from "../../../question/questions/clientPerson/Gender";
import Parents from "../../../question/questions/clientPerson/Parents";

export default {
  name: 'PersonWill',
  components: {Parents, Gender, Relationship, Dob, FullName},
  mixins: [clientPersonOptions],
  watch: {
    form: {
      deep: true,
      immediate: false,
      handler(value) {
        // person will form fields are not saved at field level as they are in json array, so watch form and save
        // whole form to json data
        this.$emit('save', value)
      }
    },
    'form.relationship': {
      immediate: false,
      deep: false,
      handler(value) {
        if (this.childListOptions.includes(value)) {
          this.form.clients = []
        } else {
          this.form.clients = [this.$store.getters.client.id]
        }
      }
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
