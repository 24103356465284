<template>
  <QuestionBase :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
                :question="question"
                :valid="!!data.length">
    <InputClients v-model="data"
                  :args="args"
                  :db="db"
                  :errors-post="errorsPost"
                  :js-errors="jsErrors"
                  :no-save="noSave"
                  :skipped="skipped"
                  @jsErrors="jsErrors=$event"
                  @serverErrors="serverErrors=$event"
                  @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputClients from "../../inputs/InputClients";

export default {
  name: 'Parents',
  mixins: [questionLoadHelpers],
  components: {
    InputClients,
    QuestionBase
  },
  props: {
    value: {
      type: Array,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: [
        Number,
        String
      ],
      required: false
    }
  },
  mounted() {
    this.setInitialParent()
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Who are the parents?',
        subTitle: 'Include only biological/adopted parents.',
        tip: null
      },
      db: {
        saveLocation: 'client_person',
        saveField: 'clients',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: false,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  },
  methods: {
    setInitialParent() {
      if (Array.isArray(this.data) && !this.data.length) {
        this.data.push(this.$store.getters.client.id)
      }
    }
  }
}
</script>