<template>
  <div>
    <CompanyName
      v-model="form.name"
      :no-save="noSave"
      :errors-post="errorsPost.name || []"
    />
    <CompanyAddress
      v-model="form.address"
      :no-save="noSave"
      :errors-post="errorsPost.address || []"
    />
  </div>
</template>

<script>
import CompanyName from "../../../question/questions/clientPerson/professional/CompanyName";
import CompanyAddress from "../../../question/questions/clientPerson/professional/CompanyAddress";

export default {
  name: "ProfessionalWill",
  components: { CompanyAddress, CompanyName },
  mixins: [],
  watch: {
    form: {
      deep: true,
      immediate: false,
      handler(value) {
        console.log("professional form watcher");
        // professional company will form fields are not saved at field level as they are in json array, so watch
        // form and save whole form to json data
        this.$emit("save", value);
      },
    },
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    noSave: {
      type: Boolean,
      default: true,
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    form: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>