export const willRelationshipHelpers = {
    methods: {
        setRelationship(person, partner, clientId) {
            let disallowedRelationshipConverts = ['friend'];
            let partnerRelationship = null;
            if (partner) {
                partnerRelationship =
                    'relationship_to_client' in partner
                        ? partner.relationship_to_client
                        : 'relationship' in partner
                            ? partner.relationship
                            : null;
            }

            let personRelationship =
                'relationship_to_client' in person ? person.relationship_to_client : 'relationship' in person ? person.relationship : null;
            if (
                this.partnerListRelationships.includes(
                    personRelationship.split(' ')[0]
                )
            ) {
                return person.relationship.split(' ')[1];
            } else if (
                clientId &&
                !person.clients.includes(clientId) &&
                !person.partner &&
                partnerRelationship &&
                personRelationship &&
                !disallowedRelationshipConverts.includes(personRelationship)
            ) {
                return `${partnerRelationship}'s ${personRelationship}`;
            }
            return personRelationship;
        },
        convertPerson(person) {
            return {
                id: person.id,
                name_first: person.name_first,
                name_middle: person.name_middle,
                name_last: person.name_last,
                relationship: person.relationship_to_client,
                recipients: [],
                recipientsType: 'equal',
                guardians: {
                    primary: [],
                    secondary: [],
                    backup: [],
                    secondaryYN: null,
                    backupYN: null
                },
                share: null,
                condition: null,
                type: 'person',
                gender: person.gender,
                partner: person.partner,
                dob: person.dob,
                clients: person.clients
            }
        }
    }
}