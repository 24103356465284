import { clientHelpers } from "@/mixins/clientHelpers";

export const willPeopleHelpers = {
    mixins: [clientHelpers],
    computed: {
        partner () {
            // find partner (there will be two partners one for each family member)
            return this.$store.getters.people.find(
                (person) => (person.partner && person.partner !== this.$store.getters.client.id) || this.isPartner(person)
            );
        },
        children() {
            return this.$store.getters.people.filter(person => this.isSonOrDaughter(person))
        }
    }
}
