import {willPeopleHelpers} from "./willPeopleHelpers";
import {willRelationshipHelpers} from "./willRelationshipHelpers";
import {willProfessionalOptions} from "./willProfessionalOptions";
import {dateFormat} from "@/mixins/dateFormat";
import {httpQuestionnaire} from "@/services";

let deepEqual = require('lodash/isEqual');

export const willGuardiansHelpers = {
    // required a details computed property: only to be used in root level Guardians file
    mixins: [willPeopleHelpers, willRelationshipHelpers, willProfessionalOptions, dateFormat],
    mounted() {
        // calculate people options
        this.setOptions()

        let attributeAdded = false
        // for old created will insert new fields into data
        if (!('separateGuardians' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'separateGuardians', null)
            attributeAdded = true
        }
        if (!('amountYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'amountYN', null)
            attributeAdded = true
        }
        if (!('secondaryYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'secondaryYN', null)
            attributeAdded = true
        }
        // add secondaryYN to all children in guardians if not there
        for (let i = 0; i < this.details.children.length; i++) {
            if (!('secondaryYN' in this.details.children[i].guardians)) {
                this.$set(this.details.children[i].guardians, 'secondaryYN', null)
                attributeAdded = true
            }
        }
        if (attributeAdded) this.save()
    },
    watch: {
        'details.children': {
            immediate: false,
            deep: true,
            handler() {
                this.checkChildrenInGuardians()
            }
        },
        storePeople: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptions()
            }
        },
        children: {
            immediate: true,
            deep: true,
            handler() {
                this.assignChildren()
            }
        },
        details: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptions()
            }
        }
    },
    data() {
        return {
            childOptions: []
        }
    },
    computed: {
        secondaryRequired() {
            return this.details.secondaryYN
        },
        details() {
            return this.form.data.guardians.details
        },
        data() {
            return this.form.data.guardians.details
        },
        storePeople() {
            return this.$store.getters.people
        },
        childrenYoung() {
            let children = this.storePeople.filter(person => this.isChildOf(person))
            return children.map(child => this.convertPerson(child))
        }
    },
    methods: {
        setOptions() {
            if (this.details.individual) {
                this.setChildOptionsData()
            } else {
                this.setOptionsData()
            }
        },
        async syncOptions() {
            this.clearAllGuardians()
            await this.addPartner()
            this.save()
        },
        async addPartner() {
            // add partner to primary guardians
            if (this.partner) {
                // find partner in primary guardians
                let partnerPrimaryIndex = this.details.primary.findIndex(client => client.id === this.partner.id)
                if (partnerPrimaryIndex >= 0 && !this.details.primaryPartner) {
                    // remove partner
                    this.details.primary.splice(partnerPrimaryIndex, 1)
                    return true
                } else if (this.details.primaryPartner) {
                    // add partner
                    this.details.primary.push(this.convertPerson(this.partner))
                    // remove partner from secondary
                    let partnerSecondaryIndex = this.details.secondary.findIndex(client => client.id === this.partner.id)
                    if (partnerSecondaryIndex >= 0) this.details.secondary.splice(partnerSecondaryIndex, 1)
                    return true
                }
            } else {
                return false
            }
        },
        clearAllGuardians() {
            this.details.primary = []
            this.details.secondary = []
            this.details.children = []
        },
        clearSecondary() {
            if (this.details.secondaryYN !== false) return
            this.details.secondary = []
            this.save()
        },
        enable() {
            if (this.details.separateGuardians === false) {
                this.clearAllGuardians()
                this.details.enabled = false
                this.details.primaryPartner = null
                this.details.secondaryYN = null
                this.details.individual = null
                this.save()
            } else {
                this.details.enabled = true
                this.details.individual = null
                this.save()
            }
        },
        setOptionsData() {
            // client_people from server with originally selected people filtered out,
            // mapped to be compatible with a Will then people relationships converted
            let serverPrimary = []
            let serverSecondary = []

            // convert people: filter out partners and children
            let serverPeople = this.storePeople.map(person => {
                // do not convert professional as they have been added from TypeOfGuardians in final form for will
                if (person.type === 'professional') return person
                // convert ClientPerson model into will person
                return this.convertPerson(person)
            }).filter(person => !this.isChild(person) &&
                !this.isChildOf(person) &&
                !this.isPartner(person))

            // filter out primary or secondary options
            let primaryIds = this.details.primary.map(person => person.id)
            let secondaryIds = this.details.secondary.map(person => person.id)
            serverPrimary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id))
            serverSecondary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id))

            // return
            this.primaryOptions = [...serverPrimary].sort((a, b) => a.id - b.id)
            this.secondaryOptions = [...serverSecondary].sort((a, b) => a.id - b.id)
        },
        async assignChildren() {
            if (!this.details.individual) return
            // assign children to individual guardian children
            let addedChild = false
            for (let i = 0; i < this.childrenYoung.length; i++) {
                // if child not in individual guardians
                if (!this.details.children.find(child => child.id === this.childrenYoung[i].id)) {
                    addedChild = true
                    this.form.data.guardians.details.children.push(this.clone(this.childrenYoung[i]))
                }
            }
            if (addedChild) this.save()

            // remove children from will guardian children, if they are not in store people (e.g. been deleted)
            let result = []
            for (let i = 0; i < this.details.children.length; i++) {
                if (this.childrenYoung.find(child => child.id === this.details.children[i].id)) {
                    result.push(this.details.children[i])
                }
            }
            if (!deepEqual(result, this.form.data.guardians.details.children)) {
                this.form.data.guardians.details.children = result
                this.save()
            }
            return true
        },
        checkChildrenInGuardians() {
            let changed = false
            // reset secondary/backup guardians if not required
            for (let i = 0; i < this.form.data.guardians.details.children.length; i++) {
                let child = this.form.data.guardians.details.children[i]
                // secondary (reset backup pas well)
                if (child.guardians.secondaryYN === false && (child.guardians.secondary.length || child.guardians.backup.length)) {
                    child.guardians.secondary = []
                    child.guardians.backup = []
                    child.guardians.backupYN = null
                    changed = true
                }
                // backup
                if (child.guardians.backupYN === false && child.guardians.backup.length) {
                    child.guardians.backup = []
                    changed = true
                }
            }
            // save

            if (changed) {
                this.save()
            }
        },
        setChildOptionsData() {
            this.childOptions = []
            for (let index = 0; index < this.form.data.guardians.details.children.length; index++) {
                this.childOptions[index] = {}
                let child = this.form.data.guardians.details.children[index]

                // client_people from server with originally selected people filtered out,
                // mapped to be compatible with a Will then people relationships converted
                let serverPrimary = []
                let serverSecondary = []
                let serverBackup = []

                // convert people: filter out partners and children
                let serverPeople = this.storePeople.map(person => {
                    // do not convert professional as they have been added from TypeOfGuardians in final form for will
                    if (person.type === 'professional') return person
                    // convert ClientPerson model into will person
                    return this.convertPerson(person)
                }).filter(person => !this.isChild(person) &&
                    !this.isChildOf(person) &&
                    !this.isPartner(person))

                // filter out primary, backup or secondary options
                let primaryIds = child.guardians.primary.map(person => person.id)
                let secondaryIds = child.guardians.secondary.map(person => person.id)
                let backupIds = child.guardians.backup.map(person => person.id)
                serverPrimary = serverPeople.filter(person =>
                    !primaryIds.includes(person.id) &&
                    !secondaryIds.includes(person.id) &&
                    !backupIds.includes(person.id))
                serverSecondary = serverPeople.filter(person =>
                    !primaryIds.includes(person.id) &&
                    !secondaryIds.includes(person.id) &&
                    !backupIds.includes(person.id))
                serverBackup = serverPeople.filter(person =>
                    !primaryIds.includes(person.id) &&
                    !secondaryIds.includes(person.id) &&
                    !backupIds.includes(person.id))

                // return sorted by id
                this.childOptions[index].primaryOptions = [...serverPrimary].sort((a, b) => a.id - b.id)
                this.childOptions[index].secondaryOptions = [...serverSecondary].sort((a, b) => a.id - b.id)
                this.childOptions[index].backupOptions = [...serverBackup].sort((a, b) => a.id - b.id)
            }
        },
        async fetchPeople() {
            let params = {
                id: this.$store.getters.client.id
                // access_code: this.$store.getters.accessCode
            }
            return httpQuestionnaire.get('client_person', {params: params}).then(
                response => {
                    this.$store.commit('people', response.data)
                    return true
                }
            ).catch(
                () => {
                    return false
                }
            )
        }

    }
}
