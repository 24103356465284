<template>
  <div>
    <InputRadioMultiple
        v-model="data"
        :db="db"
        :no-save="noSave"
        :options="options"
        :skipped="skipped"
        :errors-post="errorsPost"
        :js-errors="jsErrors"
        :args="args"
        :showOther="true"
        @skipped="$emit('skipped', $event)"
        @serverErrors="$emit('serverErrors', $event)"
        @jsErrors="$emit('jsErrors', $event)"
        @other="$emit('other')"
        class="radio-options-wide"
    >
      <InputCheckBasic v-if="showOther" v-model="dataOther" :db="dbOther" :no-save="noSave" />
    </InputRadioMultiple>
  </div>
</template>

<script>
import { clientHelpers } from "@/mixins/clientHelpers";

import InputRadioMultiple from "./InputRadioMutiple";
import InputCheckBasic from "./InputCheckBasic";

export default {
  /* client input with optional other select (used in assets) */
  name: 'InputClients',
  components: {InputCheckBasic, InputRadioMultiple},
  mixins: [clientHelpers],
  props: {
    value: {
      type: Array,
      required: true
    },
    valueOther: {
      type: Boolean,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    db: {
      type: Object,
      required: true
    },
    dbOther: {
      type: Object,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    jsErrors: {
      type: Array,
      default: () => {
        return []
      }
    },
    skipped: {
      type: Boolean,
      required: false
    },
    args: {
      type: Object,
      required: true
    },
    showOther: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    dataOther: {
      set(value) {
        this.$emit('inputOther', value)
      },
      get() {
        return this.valueOther
      }
    },
    family_clients () {
      if (this.$store.getters.family) return this.$store.getters.family.clients
      return []
    },
    options () {
      return this.family_clients.map(client => {
        return {
          label: this.clientFirstName(client),
          value: client.id
        }
      })
    }
  },
}
</script>