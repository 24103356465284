// hardcoded for saving people and fetching using the store

import { httpQuestionnaire } from "@/services";
import { clone } from "@/mixins/clone";
import { scrollTo } from "@/mixins/scrollTo";
import { toast } from '@/mixins/toast'

export const peopleSaveHelpers = {
    name: 'peopleSaveHelpers',
    mixins: [clone, scrollTo, toast],
    data () {
        return {
            loading: false
        }
    },
    methods: {
        savePerson(object, ref, callBackFunction = null, scroll = true, personAddFunction=false, disabledAddPersonFunction=null) {
            if (object && !object.id) this.post(object, ref, callBackFunction, scroll, personAddFunction, disabledAddPersonFunction)
            else if (!object && ref) this.closeCard(ref, callBackFunction, scroll)
        },
        post(object, ref, callbackFunction, scroll, personAddFunction, disabledAddPersonFunction) {
            this.clearPersonErrors()

            // convert relationship for saving
            object.relationship_to_client = object.relationship
            if (object.address && !object.address.line_1 && !object.address.postcode) delete object.address

            httpQuestionnaire.post(this.db?.personSaveLocation || 'client_person', object).then(
                (response) => {
                    // add person to data form value and save question
                    let person = this.convertPerson(response.data)

                    // modify person with optional function
                    if (personAddFunction) person = personAddFunction(person)
                    console.log('post person done', disabledAddPersonFunction)
                    // do not add person to form data and save if function says so
                    if (!disabledAddPersonFunction) {
                        this.data.push(person)
                        this.save()
                    } else if (disabledAddPersonFunction && !disabledAddPersonFunction(person)) {
                        this.data.push(person)
                        this.save()
                    } else {
                        this.toast('Can not add this person', 'Error', 'danger')
                        console.log('Adding this person is disabled')
                    }


                    // reset form
                    this.form = null
                    if (this.jsErrors) this.jsErrors = []

                    // collapse item
                    if (ref) this.closeCard(ref, scroll)

                    // callback run
                    if (callbackFunction) callbackFunction()
                    this.$store.dispatch('peopleFetch')
                }
            ).catch(
                error => {
                    this.handlePeopleErrors(error.response.data, 'errorsPost')
                }
            )
        },
        patch(object, ref = null) {
            console.log('patch person')
            this.clearPersonErrors()
            // do not save relationship as it may have been modified to 'partner's'

            object.relationship_to_client = object.relationship

            httpQuestionnaire.patch(this.db?.personSaveLocation || 'client_person', object).then(
                () => {
                    // collapse item
                    if (ref) this.closeCard(ref)
                }
            ).catch(
                error => {
                    this.handlePeopleErrors(error.response.data, 'errorsPatch')
                }
            )
        },
        deletePersonConfirm(id) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this person?',
                {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    cancelVariant: 'outline-primary',
                    okVariant: 'secondary',
                    okTitle: 'Confirm',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).
                then((confirmed) => {
                    if (confirmed) {
                        this.deletePerson(id)
                    }
                }).
                catch(() => {
                    // An error occurred
                });
        },
        deletePerson(id) {
            console.log('delete person', id)
            this.clearPersonErrors()
            let params = {
                id: id
            }
            httpQuestionnaire.delete(this.db?.personSaveLocation || 'client_person', { params: params }).then(
                () => {
                    // remove from selected people in data
                    let index = this.data.findIndex(person => person.id === id)
                    if (index >= 0) {
                        this.data.splice(index, 1)
                    }
                    this.$store.dispatch('peopleFetch')
                }
            ).catch(
                error => {
                    console.log(error)
                }
            )
        },
        clearPersonErrors() {
            this.errorsPost = {}
            this.errorsPatch = {}
        },
        handlePeopleErrors(errors, errorsLocation) {
            let keys = Object.keys(errors)
            if (this.db) {
                // if multiple forms with multiple errors of the same field then this will scroll to the first form
                // which may not be the desired behaviour
                let id = `#${keys[0]}${this.db.objectId ? this.db.objectId : ''}`
                this.scrollTo(id) // goto field () may not be in order of listed fields
            }

            this[errorsLocation] = errors
        },
        closeCard(ref, callbackFunction = null, scroll = true) {
            if (ref in this.$refs) this.$refs[ref][0].show = false
            if (scroll) this.scrollTo(`#${ref}`)
            if (callbackFunction) callbackFunction()
        }
    }
}
